function TeamCardStyleOne({ person, className }) {
  return (
    <div className={`team-member wow fadeInUp delay-0-2s ${className || ''}`}>
      <div className='image'>
        <img src={person.profile_photo || '/dummyUser.png'} alt='Team Member' />
      </div>
      <div className='member-designation'>
        <h5>
          <a href='/team-single'>{person.name}</a>
        </h5>
        <span>{person.designation}</span>
      </div>
    </div>
  )
}

export default TeamCardStyleOne
