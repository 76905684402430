import DocumentMeta from 'react-document-meta'
import Layouts from '../../helpers/Layouts/index'
import MainSection from './MainSection'

export default function PortfolioOne() {
  const meta = {
    title: `Portfolio - Quantum Skye Technologies | QSTL`,
    description:
      'QSTL is proudly serving its clients for the many years. We are a full-fledged information technology solutions provider and we deliver up-to-date digital services for your business expansion. It is irrelevant how technologically sophisticated software is written if it does not solve your problem. Our business analysts, designers and developers  and participate in the entire development process of the solution, even before the first line of code has been written. We use the right technology for the challenge, we mix proven workhorses with the latest achievements in the IT world. Ultimately, a mix of innovation and stability is required for successful and future –proof IT systems.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords:
          'services,qstl about ,web development,mobile development,seo,',
      },
    },
  }
  return (
    <DocumentMeta {...meta}>
      <Layouts pageTitle='Portfolio' breadcrumbs={[]}>
        <MainSection />
      </Layouts>
    </DocumentMeta>
  )
}
