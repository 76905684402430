import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import seoImage from '../../../assets/images/seo.jpg'
import contact from '../../../data/contact.json'
import usePagination from '../../../lib/usePagination'
import { getBlogs, getCategories } from '../../../Services'
import BlogCard from '../../helpers/Cards/BlogCard'

const MainSection = ({ queryParams }) => {
  const categoryFilter = useMemo(() => queryParams, [queryParams])
  const [blogsState, setBlogsState] = useState({
    blogs: [],
    recentBlogs: [],
  })
  const [pageLimit] = useState(6)
  const [page, setPage] = useState(1)

  const {
    isLoading: isBlogsLoading,
    refetch: blogsRefetch,
    isRefetching,
  } = useQuery('blogs', getBlogs, {
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => {
      if (queryParams) {
        setBlogsState({
          blogs: data.filter(
            (itm) => itm?.category?.name.toLowerCase() === queryParams
          ),
          recentBlogs: data.slice(0, 4),
        })
        return
      }
      setBlogsState({
        blogs: data,
        recentBlogs: data.slice(0, 4),
      })
    },
    onError: () => setPage(1),
  })

  const [categories, setCategories] = useState([])
  const { isLoading: isCategoriesLoading } = useQuery(
    'categories',
    getCategories,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => setCategories(data),
    }
  )

  const { slice: blogsData, range: pagesRange } = usePagination(
    blogsState.blogs || [],
    page,
    pageLimit
  )

  useEffect(() => blogsRefetch(), [categoryFilter, blogsRefetch])

  return (
    <section className='blog-page-area bg-lighter'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='blog-standard-content pt-80 rpt-100 pb-80 rpb-90 pr-15 rpr-0'>
              {categoryFilter && (
                <div>
                  <h3
                    style={{
                      textTransform: 'capitalize',
                      fontSize: '1.5em',
                      fontWeight: '600',
                    }}
                  >
                    Category: {queryParams}
                  </h3>
                </div>
              )}
              {isBlogsLoading || isRefetching
                ? [...Array(pageLimit).keys()].map((key) => (
                    <div key={key} style={{ marginBottom: '4em' }}>
                      <Skeleton width='100%' height='450px' />
                      <div
                        style={{
                          margin: '2em 0',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '2em',
                        }}
                      >
                        <Skeleton width='8em' height='1.5em' />
                        <Skeleton width='8em' height='1.5em' />
                      </div>

                      <Skeleton width='75%' height='3em' />

                      <hr />

                      <div
                        style={{
                          marginTop: '2em',
                        }}
                      >
                        <Skeleton count={3} width='100%' height='1em' />
                      </div>

                      <div
                        style={{
                          marginTop: '2em',
                        }}
                      >
                        <Skeleton width='10em' height='3em' />
                      </div>
                    </div>
                  ))
                : blogsData.length > 0 &&
                  blogsData.map((blog) => (
                    <div className='wow fadeInUp delay-0-2s' key={blog?.id}>
                      <BlogCard blog={blog} />
                    </div>
                  ))}
              {!isBlogsLoading && !isRefetching && blogsData.length === 0 && (
                <div>
                  <p style={{ marginTop: '2em' }}>
                    Currently there are no blog posts in this category. Visit
                    again later
                  </p>
                  <hr />
                  {blogsState.recentBlogs.length > 0 && (
                    <div className='mt-40'>
                      <div>
                        <h3 className='widget-title'>Popular Blogs</h3>
                      </div>
                      {blogsState.recentBlogs.map((blog) => (
                        <BlogCard blog={blog} />
                      ))}
                    </div>
                  )}
                </div>
              )}

              {isBlogsLoading ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}
                >
                  <Skeleton
                    circle
                    count={5}
                    inline
                    className='mx-1'
                    width='60px'
                    height='60px'
                  />
                </div>
              ) : (
                blogsData.length > 0 &&
                pagesRange > 1 && (
                  <ul className='pagination flex-wrap justify-content-center mt-80'>
                    <li
                      className={`${page === 1 && 'disabled'}`}
                      style={{ cursor: page === 1 ? 'not-allowed' : 'pointer' }}
                      onClick={() =>
                        page > 1 && setPage((currPage) => currPage - 1)
                      }
                    >
                      <span className='page-link'>
                        <i className='fas fa-chevron-left'></i>
                      </span>
                    </li>
                    {pagesRange &&
                      pagesRange?.map((curr) => (
                        <li
                          key={curr}
                          className={`page-item ${curr === page && 'active'}`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setPage(curr)}
                        >
                          <span className='page-link'>
                            {curr}
                            <span className='sr-only'>(current)</span>
                          </span>
                        </li>
                      ))}
                    <li
                      className={`${page === pagesRange.length && 'disabled'}`}
                      style={{
                        cursor:
                          page === pagesRange.length
                            ? 'not-allowed'
                            : 'pointer',
                      }}
                      onClick={() =>
                        page < pagesRange.length &&
                        setPage((currPage) => currPage + 1)
                      }
                    >
                      <span className='page-link'>
                        <i className='fas fa-chevron-right'></i>
                      </span>
                    </li>
                  </ul>
                )
              )}
            </div>
          </div>
          <div className='col-lg-4 col-sm-9 mx-sm-auto'>
            <div className='blog-sidebar my-120 rmy-100'>
              <div className='widget widget-recent-post wow fadeInUp delay-0-4s'>
                <h3 className='widget-title'>Recent News</h3>
                <div className='widget-news-wrap'>
                  {isBlogsLoading
                    ? [...Array(pageLimit).keys()].map((key) => (
                        <div
                          className='widget-news-item'
                          style={{ gap: '1.5em' }}
                          key={key}
                        >
                          <Skeleton circle width='70px' height='70px' />

                          <div className='widget-news-content'>
                            <Skeleton
                              width='10em'
                              height='1em'
                              style={{ marginBottom: '.75em' }}
                            />
                            <Skeleton width='5em' height='1em' />
                          </div>
                        </div>
                      ))
                    : blogsState?.recentBlogs &&
                      blogsState.recentBlogs.map(
                        ({ id, image, title, category, createdAt }) => (
                          <div className='widget-news-item' key={id}>
                            <img
                              // style={{ objectFit: 'cover' }}
                              src={
                                image ||
                                require(`../../../assets/images/news/news-widget-1.jpg`)
                                  .default
                              }
                              alt='News'
                              style={{ borderRadius: '0' }}
                            />
                            <div className='widget-news-content'>
                              <div
                                className='d-flex aling-items-center'
                                style={{
                                  gap: '.5em',
                                  fontSize: '.75em',
                                }}
                              >
                                <Link
                                  to={`/blog?category=${category.name.toLowerCase()}`}
                                  style={{ color: '#c11112' }}
                                >
                                  {category.name}
                                </Link>
                                <span>/</span>{' '}
                                <p className='m-0'>
                                  {createdAt &&
                                    format(
                                      new Date(createdAt),
                                      'MMMM dd, yyyy'
                                    )}
                                </p>
                              </div>
                              <h5
                                style={{
                                  fontSize: '1em',
                                  margin: '0',
                                }}
                              >
                                <Link
                                  to={`/blog/${id}`}
                                  style={{ color: '#000' }}
                                >
                                  {title}
                                </Link>
                              </h5>
                              <span className='date'>
                                <span
                                  style={{
                                    fontSize: '.875em',
                                  }}
                                >
                                  {createdAt &&
                                    format(
                                      new Date(createdAt),
                                      'MMMM dd, yyyy'
                                    )}
                                </span>
                              </span>
                            </div>
                          </div>
                        )
                      )}
                </div>
              </div>
              <div className='widget widget-category wow fadeInUp delay-0-2s'>
                <h3 className='widget-title'>Categories</h3>
                <ul className='list-style-two'>
                  {isCategoriesLoading
                    ? [...Array(5).keys()].map((key) => (
                        <div
                          key={key}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Skeleton width='10em' height='1.25em' />
                          <span>
                            <Skeleton width='2.5em' height='1.25em' />
                          </span>
                        </div>
                      ))
                    : categories.length > 0 &&
                      categories.map(
                        ({ id, name, blogs }) =>
                          blogs.length > 0 && (
                            <li key={id}>
                              <Link to={`/blog?category=${name.toLowerCase()}`}>
                                {name}
                              </Link>
                              <span>({blogs.length || 0})</span>
                            </li>
                          )
                      )}
                </ul>
              </div>
              <div
                className='widget widget-call-action wow fadeInUp delay-0-2s p-0'
                style={{ background: 'none' }}
              >
                <div
                  className='contact-form-left bgs-cover h-100 wow fadeInLeft delay-0-2s'
                  style={{ minHeight: '500px' }}
                  // style={{
                  //   background: `url(${background}) no-repeat 0px 40px / contain`,
                  // }}
                >
                  <div
                    className='widget contact-info-widget contact-image-number style-two bgs-cover overlay'
                    style={{
                      backgroundImage: `url(${seoImage}})`,
                    }}
                  >
                    <div className='contact-informations text-white'>
                      <h3>Don't hesitate to contact us</h3>
                      <ul className='contact-info'>
                        <li>
                          <i className='fas fa-phone-alt'></i>
                          <div className='content text-left'>
                            <span>Call Us</span>
                            <h5>
                              <a href={`callto:${contact.phone}`}>
                                {contact.phone || ''}
                              </a>
                            </h5>
                          </div>
                        </li>
                        <li>
                          <i className='fas fa-envelope'></i>
                          <div className='content text-left'>
                            <span>Write to Us</span>
                            <h5>
                              <a href={`mailto:${contact.email}`}>
                                {contact.email || ''}
                              </a>
                            </h5>
                          </div>
                        </li>
                        <li>
                          <i className='fas fa-clock'></i>
                          <div className='content text-left'>
                            <span>Office hours</span>
                            <h5> {contact.timing || ''}</h5>
                          </div>
                        </li>
                        <li>
                          <div className='menu-btn d-flex justify-content-center w-100'>
                            <Link
                              to='/contact'
                              className='theme-btn bordered-btn'
                              style={{ borderColor: '#c11112 !important' }}
                            >
                              Contact Us
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default MainSection
