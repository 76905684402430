import DocumentMeta from 'react-document-meta'
import Layouts from '../../helpers/Layouts'
import AboutSection from '../../HomeThree/AboutSection'
import TestimonialSection from '../../HomeThree/TestimonialSection'
import WorkProcessSection from '../../HomeThree/WorkProcessSection'
import GetInTouchSection from './GetInTouchSection'

export default function AboutOne() {
  const meta = {
    title: 'About Quantum Skye Technologies | QSTL',
    description:
      'QSTL has delivered excellent products from the inception of the idea, to requirements, design, coding, testing, and disseminating. We develop innovative and creative products and services that provide total communication and information solutions',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'aboutus,qstl about ,web development,mobile development,seo,',
      },
    },
  }
  const about = {
    taglines: [
      'Building Products of The Future.',
      'Transforming vision to value.',
      'Crafting your ideas and bringing them to life.',
      'Perfect Blend of Superlative IT Services with Cutting-Edge Technology.',
      'We Design, Develop & Execute Digital Experiences.',
      'We turn ideas in to products of future.',
    ],
  }
  return (
    <DocumentMeta {...meta}>
      <Layouts pageTitle='About QSTL' breadcrumbs={[]}>
        <AboutSection className='pb-240' about={about} />
        <WorkProcessSection />
        <TestimonialSection />
        <GetInTouchSection />
      </Layouts>
    </DocumentMeta>
  )
}
