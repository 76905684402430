import { useState } from 'react'
import { Link } from 'react-router-dom'
import contact from '../../../data/contact.json'
import { services } from '../../../data/services.json'
import './style.css'

function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState('0px')
  const [item, setItem] = useState('')
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length
    if (getItems > 0) {
      if (item !== value) {
        setSize(`${50 * getItems}px`)
        setItem(value)
      } else {
        setItem('')
      }
    }
  }
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
      ></div>
      <div className='offcanvas_menu'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div
                className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}
              >
                <a
                  href='/'
                  className='offcanvas-brand text-center mb-40 d-block'
                >
                  <h2>QSTL</h2>
                </a>
                <div id='menu' className='text-left '>
                  <ul className='offcanvas_main_menu'>
                    <li
                      onClick={(e) => handler(e, 'home')}
                      id='home'
                      className='menu-item-has-children active'
                    >
                      <Link to='/'>Home</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, 'about')}
                      id='about'
                      className='menu-item-has-children active'
                    >
                      <Link to='/about'>About</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, 'service')}
                      id='service'
                      className='menu-item-has-children active'
                    >
                      <span className='menu-expand'>
                        <i className='fa fa-angle-down'></i>
                      </span>
                      <a href='#'>Service</a>
                      <ul
                        className='sub-menu'
                        style={{
                          height: item === 'service' ? itemSize : '0px',
                        }}
                      >
                        {services.map((service) => (
                          <li
                            key={service.id}
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Link to={`/${service.slogan}`}>
                              {service.accronym
                                ? service.accronym
                                : service.service_name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, 'portfolio')}
                      id='portfolio'
                      className='menu-item-has-children active'
                    >
                      <Link to='/portfolio'>Portfolio</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, 'portfolio')}
                      id='portfolio'
                      className='menu-item-has-children active'
                    >
                      <Link to='/blog'>Blog</Link>
                    </li>

                    <li
                      onClick={(e) => handler(e, 'contact')}
                      id='contact'
                      className='menu-item-has-children active'
                    >
                      <Link to='/contact'>Contact</Link>
                    </li>
                  </ul>
                </div>
                <div
                  className='offcanvas-social'
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div className='social-style-one mb-10'>
                    <a
                      href={contact?.social?.facebook || 'http://facebook.com'}
                    >
                      <i className='fab fa-facebook-f'></i>
                    </a>
                    <a href={contact?.social?.twitter || 'http://twitter.com'}>
                      <i className='fab fa-twitter'></i>
                    </a>
                    <a
                      href={
                        contact?.social?.instagram ||
                        'https://www.instagram.com/'
                      }
                    >
                      <i className='fab fa-instagram'></i>
                    </a>
                  </div>
                </div>
                <div className='footer-widget-info'>
                  <ul>
                    <li>
                      <a href='#'>
                        <i className='fa fa-envelope'></i> {contact.email}
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i className='fa fa-phone'></i>
                        {contact.phone}
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i className='fa fa-map-marker-alt'></i>
                        {contact.address}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Drawer
