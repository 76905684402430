import { format } from 'date-fns'
import DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import seoImage from '../../../assets/images/seo.jpg'
import contact from '../../../data/contact.json'

export default function MainSection({
  blog: { isBlogLoading, details },
  recentBlogs,
  categories: { categories, isCategoriesLoading },
}) {
  const [commentForm, setCommentForm] = useState({
    fullName: '',
    message: '',
    email: '',
  })

  const onChangeHandler = (key, value) =>
    setCommentForm((prevFields) => {
      const res = {
        ...prevFields,
        [key]: value,
      }
      return res
    })

  const onSubmitHandler = (e) => {
    e.preventDefault()

    console.log(commentForm)
  }

  // Make all links of details opens in to the new tab.
  useEffect(() => {
    if (!details?.description) return
    const links = document
      ?.querySelector('#dangerouslyHtmlElement')
      .getElementsByTagName('a')

    if (!links?.length) return

    Object.keys(links)?.forEach((key) =>
      links[key].setAttribute('target', '_blank')
    )
  }, [details?.description])

  return (
    <section className='blog-details-area bg-lighter'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 bg-white'>
            {isBlogLoading ? (
              <div style={{ paddingTop: '7em' }}>
                <Skeleton width='100%' height='450px' />
                <div
                  style={{
                    margin: '2em 0',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2em',
                  }}
                >
                  <Skeleton width='8em' height='1.5em' />
                  <Skeleton width='8em' height='1.5em' />
                </div>

                <Skeleton width='75%' height='3em' />

                <hr />

                <div
                  style={{
                    marginTop: '2em',
                  }}
                >
                  <Skeleton count={5} width='100%' height='1em' />
                  <br />
                  <Skeleton count={5} width='100%' height='1em' />
                  <br />
                  <Skeleton count={5} width='100%' height='1em' />
                </div>
              </div>
            ) : (
              <div className='blog-details-content pt-120 rpt-100 pb-95 rpb-75 pr-15 rpr-0'>
                <div className='image wow fadeInUp delay-0-2s'>
                  <img
                    src={
                      details?.image ||
                      require(`../../../assets/images/news/blog-standard-1.jpg`)
                        .default
                    }
                    alt='Blog Standard'
                  />
                </div>
                <div className='blog-header'>
                  <ul className='post-meta-item mr-15'>
                    <li>
                      <i className='far fa-user'></i>
                      <span>QSTL</span>
                    </li>
                    <li>
                      <i className='fas fa-calendar-alt'></i>
                      <span>
                        {details?.createdAt &&
                          format(new Date(details?.createdAt), 'MMMM dd, yyyy')}
                      </span>
                    </li>
                  </ul>
                  {/* <div className='social-style-one'>
                  <span>Share Now</span>
                  <a href='http://facebook.com'>
                    <i className='fab fa-facebook-f'></i>
                  </a>
                  <a href='http://twitter.com'>
                    <i className='fab fa-twitter'></i>
                  </a>
                  <a href='https://google.com/'>
                    <i className='fab fa-google-plus-g'></i>
                  </a>
                </div> */}
                </div>
                <div
                  id='dangerouslyHtmlElement'
                  className='nestedMarginNone dangerouslyHtml'
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(details?.description || ''),
                  }}
                ></div>
                {/* <div className='blog-footer mb-40'>
                <div className='popular-tags mb-15'>
                  <b>Popular Tag :</b> <a href='#'>IT Services</a>{' '}
                  <a href='#'>Technology</a> <a href='#'>Services</a>
                </div>
                <div className='social-style-two'>
                  <span>Share</span>
                  <a href='http://facebook.com'>
                    <i className='fab fa-facebook-f'></i>
                  </a>
                  <a href='http://twitter.com'>
                    <i className='fab fa-twitter'></i>
                  </a>
                  <a href='https://google.com/'>
                    <i className='fab fa-google-plus-g'></i>
                  </a>
                  <a href='https://www.instagram.com/'>
                    <i className='fab fa-instagram'></i>
                  </a>
                </div>
              </div> */}
                {/* <div className='row wow fadeInUp delay-0-2s'>
                <div className='col-sm-6'>
                  <a href='/blog/blog-details' className='next-prev-blog'>
                    <h4>Building Pub Sub Service Using Node And Redis</h4>
                  </a>
                </div>
                <div className='col-sm-6'>
                  <a href='/blog/blog-details' className='next-prev-blog'>
                    <h4>Once Upon Time Use Story For Better Engagement</h4>
                  </a>
                </div>
              </div> */}
                <hr />
                {/* <div className='comments-wrap mt-55 wow fadeInUp delay-0-2s'>
                <h3 className='comment-title mb-55'>Client’s Comments</h3>
                <div className='comment-item'>
                  <div className='author-image'>
                    <img
                      src={
                        require(`../../../assets/images/news/comment-author-1.jpg`)
                          .default
                      }
                      alt='Author'
                    />
                  </div>
                  <div className='comment-details'>
                    <div className='name-date'>
                      <h5>Alexzeder Alex</h5>
                      <span className='date'>25 Jan 2022</span>
                    </div>
                    <p>
                      But I must explain to you how all this mistaken idea of
                      denouncing pleasure and praising pain was born and I will
                      give you a complete
                    </p>
                    <a href='#' className='reply'>
                      Reply Commets
                    </a>
                  </div>
                </div>
                <div className='comment-item child-comment'>
                  <div className='author-image'>
                    <img
                      src={
                        require(`../../../assets/images/news/comment-author-2.jpg`)
                          .default
                      }
                      alt='Author'
                    />
                  </div>
                  <div className='comment-details'>
                    <div className='name-date'>
                      <h5>John F. Medina</h5>
                      <span className='date'>25 Jan 2022</span>
                    </div>
                    <p>
                      But I must explain to you how all this mistaken idea of
                      denouncing pleasure and praising pain was born and I will
                      give you
                    </p>
                    <a href='#' className='reply'>
                      Reply Commets
                    </a>
                  </div>
                </div>
                <div className='comment-item'>
                  <div className='author-image'>
                    <img
                      src={
                        require(`../../../assets/images/news/comment-author-3.jpg`)
                          .default
                      }
                      alt='Author'
                    />
                  </div>
                  <div className='comment-details'>
                    <div className='name-date'>
                      <h5>Alexzeder Alex</h5>
                      <span className='date'>25 Jan 2022</span>
                    </div>
                    <p>
                      But I must explain to you how all this mistaken idea of
                      denouncing pleasure and praising pain was born and I will
                      give you a complete
                    </p>
                    <a href='#' className='reply'>
                      Reply Commets
                    </a>
                  </div>
                </div>
              </div> */}
                <div className='comment-form-wrap mt-60 wow fadeInUp delay-0-2s'>
                  <h3 className='comment-title mb-55'>Send A Message</h3>
                  <form
                    id='comment-form'
                    className='comment-form'
                    name='comment-form'
                    method='post'
                    onSubmit={onSubmitHandler}
                  >
                    <div className='row clearfix justify-content-center'>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='full-name'>
                            <i className='far fa-user'></i>
                          </label>
                          <input
                            type='text'
                            id='full-name'
                            name='fullName'
                            className='form-control'
                            placeholder='Your Full Name'
                            value={commentForm.fullName}
                            onChange={({ target: { name, value } }) =>
                              onChangeHandler(name, value)
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='email'>
                            <i className='far fa-envelope'></i>
                          </label>
                          <input
                            type='email'
                            id='email'
                            name='email'
                            className='form-control'
                            placeholder='Your Email'
                            onChange={({ target: { name, value } }) =>
                              onChangeHandler(name, value)
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='comments'>
                            <i className='fas fa-pencil-alt'></i>
                          </label>
                          <textarea
                            name='message'
                            id='comments'
                            className='form-control'
                            rows='4'
                            placeholder='Write Message'
                            onChange={({ target: { name, value } }) =>
                              onChangeHandler(name, value)
                            }
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <button type='submit' className='theme-btn'>
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
          <div
            className='col-lg-4 col-sm-9 mx-sm-auto'
            style={{ paddingBottom: '4em' }}
          >
            <div className='blog-sidebar mt-120 rmy-100'>
              {/* <div className='widget widget-search wow fadeInUp delay-0-2s'>
                <form action='#'>
                  <input
                    type='text'
                    placeholder='Search'
                    className='searchbox'
                    required
                  />
                  <button
                    type='submit'
                    className='searchbutton fa fa-search'
                  ></button>
                </form>
              </div> */}
              <div className='widget widget-recent-post wow fadeInUp delay-0-4s'>
                <h3 className='widget-title'>Recent News</h3>

                <div className='widget-news-wrap'>
                  {recentBlogs &&
                    recentBlogs.map(
                      ({ id, image, category, title, createdAt }) => (
                        <div className='widget-news-item' key={id}>
                          <img
                            // style={{ objectFit: 'cover' }}
                            src={
                              image ||
                              require(`../../../assets/images/news/news-widget-1.jpg`)
                                .default
                            }
                            alt='News'
                            style={{ borderRadius: '0' }}
                          />
                          <div className='widget-news-content'>
                            <div
                              className='d-flex aling-items-center'
                              style={{
                                gap: '.5em',
                                fontSize: '.75em',
                              }}
                            >
                              <Link
                                to={`/blog?category=${category.name.toLowerCase()}`}
                                style={{ color: '#c11112' }}
                              >
                                {category.name}
                              </Link>
                              <span>/</span>{' '}
                              <p className='m-0'>
                                {createdAt &&
                                  format(new Date(createdAt), 'MMMM dd, yyyy')}
                              </p>
                            </div>
                            <h5
                              style={{
                                fontSize: '1em',
                                margin: '0',
                              }}
                            >
                              <Link
                                to={`/blog/${title
                                  .split(' ')
                                  .join('-')
                                  .toLowerCase()}?blog_id=${id}`}
                                style={{ color: '#000' }}
                              >
                                {title}
                              </Link>
                            </h5>
                            <span className='date'>
                              <span
                                style={{
                                  fontSize: '.875em',
                                }}
                              >
                                {createdAt &&
                                  format(new Date(createdAt), 'MMMM dd, yyyy')}
                              </span>
                            </span>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className='widget widget-category wow fadeInUp delay-0-2s'>
                <h3 className='widget-title'>Category</h3>

                <ul className='list-style-two'>
                  {isCategoriesLoading
                    ? [...Array(5).keys()].map((key) => (
                        <div
                          key={key}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Skeleton width='10em' height='1.25em' />
                          <span>
                            <Skeleton width='2.5em' height='1.25em' />
                          </span>
                        </div>
                      ))
                    : categories.length > 0 &&
                      categories.map(
                        ({ id, name, blogs }) =>
                          blogs.length > 0 && (
                            <li key={id}>
                              <Link to={`/blog?category=${name.toLowerCase()}`}>
                                {name}
                              </Link>
                              <span>({blogs.length || 0})</span>
                            </li>
                          )
                      )}
                </ul>
              </div>
              {/* <div className='widget widget-tag-cloud wow fadeInUp delay-0-2s'>
                <h3 className='widget-title'>Category</h3>
                <div className='tags'>
                  <a href='#'>Cleaning</a>
                  <a href='#'>Business</a>
                  <a href='#'>Booking</a>
                  <a href='#'>car</a>
                  <a href='#'>House</a>
                  <a href='#'>Apartment</a>
                  <a href='#'>Washing</a>
                  <a href='#'>Agency</a>
                  <a href='#'>Listing</a>
                </div>
              </div> */}
              <div
                className='widget widget-call-action wow fadeInUp delay-0-2s p-0'
                style={{ background: 'none' }}
              >
                <div
                  className='contact-form-left bgs-cover h-100 wow fadeInLeft delay-0-2s'
                  style={{ minHeight: '500px' }}
                  // style={{
                  //   background: `url(${background}) no-repeat 0px 40px / contain`,
                  // }}
                >
                  <div
                    className='widget contact-info-widget contact-image-number style-two bgs-cover overlay'
                    style={{
                      backgroundImage: `url(${seoImage}})`,
                    }}
                  >
                    <div className='contact-informations text-white'>
                      <h3>Don't hesitate to contact us</h3>
                      <ul className='contact-info'>
                        <li>
                          <i className='fas fa-phone-alt'></i>
                          <div className='content text-left'>
                            <span>Call Us</span>
                            <h5>
                              <a href={`callto:${contact.phone}`}>
                                {contact.phone || ''}
                              </a>
                            </h5>
                          </div>
                        </li>
                        <li>
                          <i className='fas fa-envelope'></i>
                          <div className='content text-left'>
                            <span>Write to Us</span>
                            <h5>
                              <a href={`mailto:${contact.email}`}>
                                {contact.email || ''}
                              </a>
                            </h5>
                          </div>
                        </li>
                        <li>
                          <i className='fas fa-clock'></i>
                          <div className='content text-left'>
                            <span>Office hours</span>
                            <h5> {contact.timing || ''}</h5>
                          </div>
                        </li>
                        <li>
                          <div className='menu-btn d-flex justify-content-center w-100'>
                            <Link
                              to='/contact'
                              className='theme-btn bordered-btn'
                            >
                              Contact Us
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
