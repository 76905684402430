import { format } from 'date-fns'
import DOMPurify from 'dompurify'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const BlogCard = ({ blog, ...props }) => {
  const { id, title, category, description, image, createdAt } = blog

  // Make all links of details opens in to the new tab.
  useEffect(() => {
    if (!description) return
    const links = document
      ?.querySelector('#dangerouslyHtmlElement')
      .getElementsByTagName('a')

    if (!links?.length) return

    Object.keys(links)?.forEach((key) =>
      links[key].setAttribute('target', '_blank')
    )
  }, [description])
  return (
    <div
      className='d-flex flex-md-row flex-column'
      style={{ margin: '2em 0' }}
      {...props}
    >
      <div style={{ flex: '1', border: '1px solid', borderColor: '#ccc' }}>
        <Link to={`/blog/${id}`} style={{ color: '#000', fontWeight: 'bold' }}>
          <img
            src={
              image ||
              require(`../../../assets/images/news/blog-standard-1.jpg`).default
            }
            alt='Blogs'
            style={{ width: '100%', height: '240px', objectFit: 'cover' }}
          />
        </Link>
      </div>
      <div
        style={{
          flex: '1',
          boxSizing: 'border-box',
          // paddingLeft: '1.5em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        className='p-0 py-3 pl-md-4 py-md-0'
      >
        <div>
          <div
            className='d-flex aling-items-center'
            style={{ gap: '.5em', fontSize: '.875em', marginBottom: '.5em' }}
          >
            <Link
              to={`/blog?category=${category.name.toLowerCase()}`}
              style={{ color: '#c11112' }}
            >
              {category.name}
            </Link>
            <span>/</span>{' '}
            <p className='m-0'>
              {createdAt && format(new Date(createdAt), 'MMMM dd, yyyy')}
            </p>
          </div>
          <div>
            <h3>
              <Link
                to={`/blog/${id}`}
                style={{ color: '#000', fontWeight: 'bold' }}
              >
                {title}
              </Link>
            </h3>
          </div>
          <div>
            <div
              style={{
                height: '100%',
                maxHeight: '120px',
                overflow: 'hidden',
                WebkitLineClamp: '4',
                textAlign: 'justify',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}
              id='dangerouslyHtmlElement'
              className='dangerouslyHtml dangerouslyHtmlCard nestedMarginNone nestedFontSizeSame'
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${description}...` || ''),
              }}
            ></div>{' '}
          </div>
        </div>
        <div>
          <Link
            to={`/blog/${id}`}
            style={{ color: '#000', fontWeight: 'bold' }}
          >
            <button
              type='button'
              className='mt-3 mt-md-0'
              style={{
                // marginBottom: '.5em',
                fontSize: '.875em',
                background: 'none',
                border: '1px solid',
                borderColor: 'inherit',
                padding: '.25em .75em',
                color: '#c11112',
              }}
            >
              Read more
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BlogCard
