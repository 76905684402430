import { Link } from "react-router-dom";
import { services } from "../../data/services.json";

function FeatureSection() {
  return (
    <section className="featured-section bgs-cover pt-100 rpt-150 pb-120 rpb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="section-title text-center mb-35">
              <span className="sub-title">WHO WE ARE</span>
              <h2>We deal with the aspects of professional IT Services</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          {services.map((service, index) => (
            <div className="col-xl-4 col-md-6" key={`${service.id}_${index}`}>
              <div className="feature-item wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className={`flaticon ${service.iconClass}`}></i>
                </div>
                <div className="feature-content">
                  <h5>
                    {service.service_name === "Search Engine Optimization"
                      ? "SEO"
                      : service.service_name}
                  </h5>
                  <p>{service.card_title}</p>
                  <Link to={`/${service.slogan}`} className="learn-more">
                    Read More <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="feature-btn text-center mt-20">
          <a href="/web-development" className="theme-btn">
            view all services
          </a>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
