import { Link } from 'react-router-dom'
import background from '../../assets/images/background/progress.png'
import { workprocess } from '../../data/workprocess.json'

export default function WorkProcessSection() {
  return (
    <section
      className='work-progress-three bg-black text-white py-85 rpb-65'
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className='container'>
        {
          <div className='work-progress-inner-three'>
            <div className='row justify-content-center'>
              {workprocess.map((process, index) => (
                <div className='col-lg-4 col-sm-6'>
                  <div className='progress-item-two style-two wow fadeInUp delay-0-2s'>
                    <span className='progress-step'>0{1 + index}</span>
                    <div className='icon'>
                      <i className={process.iconClass || ''}></i>
                    </div>
                    <h3>{process.proces_name}</h3>
                    <p>{process.description}</p>
                    <Link
                      to={`/service-details/${process.serviceid}`}
                      className='learn-more'
                    >
                      Read More <i className='fas fa-arrow-right'></i>
                    </Link>
                  </div>
                </div>
              ))}
              {/* <div className='col-lg-4 col-sm-6'>
              <div className='progress-item-two style-two wow fadeInUp delay-0-4s'>
                <span className='progress-step'>02</span>
                <div className='icon'>
                  <i className='flaticon flaticon-vector'></i>
                </div>
                <h3>
                  Web <br />
                  Development
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore
                </p>
                <a href='/service-details' className='learn-more'>
                  Read More <i className='fas fa-arrow-right'></i>
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <div className='progress-item-two style-two wow fadeInUp delay-0-6s no-border mb-0'>
                <span className='progress-step'>03</span>
                <div className='icon'>
                  <i className='flaticon flaticon-like-1'></i>
                </div>
                <h3>
                  Mobile App <br />
                  Development
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore
                </p>
                <a href='/service-details' className='learn-more'>
                  Read More <i className='fas fa-arrow-right'></i>
                </a>
              </div>
            </div> */}
            </div>
          </div>
        }
      </div>
    </section>
  )
}
