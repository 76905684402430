import { useState } from 'react'
import DocumentMeta from 'react-document-meta'
import { useParams } from 'react-router-dom'
import { services } from '../../../data/services.json'
import Layouts from '../../helpers/Layouts'
import MainSection from './MainSection'

export default function ServiceDetails() {
  const { serviceName } = useParams()
  const meta = {
    title: `${
      services.find((service) => service.slogan === serviceName).service_name
    } Agency | QSTL`,
    description: services.find((service) => service.slogan === serviceName)
      .descriptions[0],

    meta: {
      charset: 'utf-8',
      name: {
        keywords:
          'services,qstl about ,web development,mobile development,seo,',
      },
    },
  }

  const [pageTitle, setPageTitle] = useState('Web Development')

  return (
    <DocumentMeta {...meta}>
      <Layouts pageTitle={pageTitle} breadcrumbs={[]}>
        <MainSection setPageTitle={setPageTitle} />
        {/* <WorkProcessSection /> */}
      </Layouts>
    </DocumentMeta>
  )
}
