import { Link } from 'react-router-dom'
import Background from '../../../../assets/images/footer/footer-bg-map.png'
import logo from '../../../../assets/images/logos/logo.png'
import contact from '../../../../data/contact.json'

function FooterHomeOne({ className }) {
  return (
    <footer
      className={`main-footer footer-two bgs-cover text-white ${
        className || ''
      }`}
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className='footer-widget-area bgs-cover pt-100 pb-50'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-3 col-sm-12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='footer-widget about-widget'>
                <div className='footer-logo mb-35'>
                  <Link to='/'>
                    <img src={logo} alt='Logo' />
                  </Link>
                </div>
                <p style={{ marginLeft: '15px' }}>
                  Innovating and developing remarkable custom and enterprise
                  solutions that will be giving a competitive edge to
                  businesses.
                </p>
              </div>
            </div>
            <div className='col-lg-2 col-md-4 col-sm-6'>
              <div className='footer-widget link-widget ml-20 rml-0'>
                <h4 className='footer-title'>Page Links</h4>
                <ul className='list-style-two'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>
                    <Link to='/web-development'>services</Link>
                  </li>
                  <li>
                    <Link to='/portfolio'>portfolio</Link>
                  </li>
                  <li>
                    <Link to='/blog'>blog</Link>
                  </li>
                  <li>
                    <Link to='/contact'>contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6'>
              <div className='footer-widget link-widget ml-20 rml-0'>
                <h4 className='footer-title'>Useful Links</h4>
                <ul className='list-style-two'>
                  <li>
                    <Link to='#'>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to='#'>Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6'>
              <div className='footer-widget contact-widget '>
                <h4 className='footer-title contact-title'>Get in touch</h4>
                <ul
                  className='list-style-two'
                  style={{
                    marginBottom: 5,
                  }}
                >
                  <div className='link-widget rml-0'>
                    <ul className='contact-info'>
                      <li>
                        <i className='fas fa-map-marker-alt'></i>
                        <span>{contact.address || ''}</span>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="footer-widget link-widget ml-20 rml-0">
                    <ul className="contact-info">
                      <li>
                        <i className="fas fa-clock"></i>
                        <span>{contact.timing || ""}</span>
                      </li>
                    </ul>
                  </div> */}
                  <div className='link-widget rml-0'>
                    <ul className='contact-info'>
                      <li>
                        <i className='fas fa-phone-alt'></i>
                        <span>{contact.phone || ''}</span>
                      </li>
                    </ul>
                  </div>
                  <div className='link-widget rml-0'>
                    <ul className='contact-info'>
                      <li>
                        <i className='fas fa-envelope'></i>
                        <span
                          style={{
                            textTransform: 'lowercase',
                          }}
                        >
                          {contact.email || ''}
                        </span>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copyright-area'>
        <div className='container'>
          <div className='copyright-inner pt-15'>
            <div className='social-style-one mb-10'>
              <a
                href={contact?.social?.facebook || 'http://facebook.com'}
                target='_blank'
                rel='noreferrer'
              >
                <i className='fab fa-facebook-f'></i>
              </a>
              <a
                href={contact?.social?.twitter || 'http://twitter.com'}
                target='_blank'
                rel='noreferrer'
              >
                <i className='fab fa-twitter'></i>
              </a>
              <a
                href={
                  contact?.social?.instagram || 'https://www.instagram.com/'
                }
                target='_blank'
                rel='noreferrer'
              >
                <i className='fab fa-instagram'></i>
              </a>
              <a
                href={contact?.social?.linkedIn || 'https://www.linkedin.com/'}
                target='_blank'
                rel='noreferrer'
              >
                <i className='fab fa-linkedin'></i>
              </a>
            </div>
            <p>© 2022 Quantum Skye Technologies Ltd. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterHomeOne
