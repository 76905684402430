import FooterHomeOne from '../../partials/Footers/FooterHomeOne'
import HeaderHomeOne from '../../partials/Headers/HeaderHomeThree'
import Hero from './Hero'

export default function Layouts({
  children,
  pageTitle,
  breadcrumbs = [],
  bg = '',
}) {
  return (
    <>
      <HeaderHomeOne />
      <Hero pageTitle={pageTitle} breadcrumbs={breadcrumbs} bg={bg} />
      {children && children}
      <FooterHomeOne />
    </>
  )
}
