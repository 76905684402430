import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { topbar } from 'react-router-loading'
import Routers from './Routers'

const queryClient = new QueryClient()

topbar.config({
  autoRun: false,
  barThickness: 5,
  barColors: {
    0: 'rgba(193, 17, 18, 0.63)',
    0.3: 'rgb(29, 27, 27 , 0.7)',
    1.0: 'rgba(231, 76,  60,  .7)',
  },
  shadowBlur: 5,
  shadowColor: 'red',
  className: 'topbar',
})

function App() {
  const { rtl } = useSelector((state) => state.rtl)
  const html = document.getElementsByTagName('html')
  // const [settingToggle, setToggle] = useToggle(false)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/home-eight') {
      if (rtl) {
        html[0].dir = 'rtl'
      } else {
        html[0].dir = ''
      }
    }
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location])

  return (
    <QueryClientProvider client={queryClient}>
      <div className={rtl ? 'direction-rtl' : ''}>
        <Routers />
      </div>
    </QueryClientProvider>
  )
}

export default App
