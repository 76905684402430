import { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import seoImage from '../../../assets/images/seo.jpg'
import contact from '../../../data/contact.json'
import { services } from '../../../data/services.json'

export default function MainSection({ setPageTitle }) {
  const { serviceName } = useParams()

  const [currentService, setCurrentService] = useState(null)

  const getCurrentService = useCallback(
    (slogan) => {
      const data = services.find((service) => service.slogan === slogan)
      setPageTitle(data?.service_name)
      setCurrentService(data)
    },
    [setPageTitle]
  )

  useEffect(() => {
    getCurrentService(serviceName)
  })

  return (
    currentService && (
      <section className='service-details pt-120 rpt-100 pb-100 rpb-80'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='service-sidebar rmb-55 wow fadeInLeft delay-0-2s'>
                <div className='widget widget-services'>
                  <ul>
                    {services &&
                      services.map((service, index) => (
                        <li
                          className={
                            service.slogan === serviceName ? 'active' : ''
                          }
                          key={index}
                        >
                          <Link to={`/${service?.slogan}`}>
                            {service.service_name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
                <div
                  className='widget contact-info-widget contact-image-number style-two bgs-cover overlay'
                  style={{
                    backgroundImage: `url(${seoImage}})`,
                  }}
                >
                  <div className='contact-informations text-white'>
                    <h3>Don't hesitate to contact us</h3>
                    <ul className='contact-info'>
                      <li>
                        <i className='fas fa-phone-alt'></i>
                        <div className='content'>
                          <span>Call Us</span>
                          <h5>
                            <a href={`callto:${contact.phone}`}>
                              {contact.phone || ''}
                            </a>
                          </h5>
                        </div>
                      </li>
                      <li>
                        <i className='fas fa-envelope'></i>
                        <div className='content'>
                          <span>Write to Us</span>
                          <h5>
                            <a href={`mailto:${contact.email}`}>
                              {contact.email || ''}
                            </a>
                          </h5>
                        </div>
                      </li>
                      <li>
                        <i className='fas fa-clock'></i>
                        <div className='content'>
                          <span>Office hours</span>
                          <h5> {contact.timing || ''}</h5>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='service-details-content wow fadeInRight delay-0-2s'>
                <div className='image mb-40 service-detail-image overlay'>
                  <img
                    className='overlay'
                    style={{
                      maxHeight: 400,
                      minWidth: '100%',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      opacity: 0.9,
                      backgroundColor: '#0d004c',
                    }}
                    src={
                      require(`../../../assets/images/${currentService.image}`)
                        .default
                    }
                    alt='Service Details'
                  />
                </div>
                <h2>{currentService?.service_name}</h2>
                {/* <p>{currentService?.descriptions[0]}</p> */}
                {currentService?.descriptions.map((desc, index) => (
                  <p key={index}>{desc}</p>
                ))}
                {/* {currentService?.types.length > 0 && (
                <h3 className='pb-0 pt-15'>
                  Types of {currentService?.service_name}
                </h3>
              )} */}
                <ul className='list-style-three pt-0 pb-30'>
                  {currentService?.types.length > 0 &&
                    currentService?.types.map(
                      ({ title }, index) =>
                        title !== '' && <li key={index}>{title}</li>
                    )}
                </ul>

                {currentService?.types.length > 0 &&
                  currentService?.types.map(
                    ({ title, descriptions }, index) => (
                      <div key={index}>
                        {title !== '' && <h3>{title}</h3>}
                        <p>{descriptions[0]}</p>
                      </div>
                    )
                  )}
                <div className='row pt-25'>
                  {/* {services &&
                  services.map((service, index) => (
                    <div
                      key={index}
                      className="col-xl-4 col-lg-6 col-md-4 col-sm-6"
                    >
                      <div className="service-normal style-two wow fadeInUp delay-0-2s">
                        <div className="icon">
                          <i className={`flaticon ${service.iconClass}`}></i>
                        </div>
                        <h6>{service.service_name}</h6>
                        <p>{service.card_title}</p>
                      </div>
                    </div>
                  ))} */}

                  {/* <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6'>
                  <div className='service-normal style-two wow fadeInUp delay-0-4s'>
                    <div className='icon'>
                      <i className='flaticon flaticon-analysis'></i>
                    </div>
                    <h6>Machine Learning</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor{' '}
                    </p>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6'>
                  <div className='service-normal style-two wow fadeInUp delay-0-6s'>
                    <div className='icon'>
                      <i className='flaticon flaticon-design-process'></i>
                    </div>
                    <h6>Software Development</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor{' '}
                    </p>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  )
}
