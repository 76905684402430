/* eslint-disable */

import { useEffect, useState } from 'react'

const calculateRange = (data, rowsPerPage) => {
  const range = []
  const num = Math.ceil(data.length / rowsPerPage)
  for (let i = 1; i <= num; i++) {
    range.push(i)
  }
  return range
}

const sliceData = (data, page, rowsPerPage) => {
  const indexedData = data.map((item, key) => {
    return { ...item, index: key + 1 }
  })
  return indexedData.slice((page - 1) * rowsPerPage, page * rowsPerPage)
}

const usePagination = (data = [], page = 1, rowsPerPage = 5) => {
  const [tableRange, setTableRange] = useState([])
  const [slice, setSlice] = useState([])

  useEffect(() => {
    const range = calculateRange(data, rowsPerPage)
    setTableRange([...range])

    const slice = sliceData(data, page, rowsPerPage)
    setSlice([...slice])
  }, [data, setTableRange, page, setSlice])

  return { slice, range: tableRange }
}

export default usePagination
