import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from 'react-query'
import { getTeam } from '../../Services/Team'
import TeamCardStyleOne from '../helpers/Cards/TeamCardStyleOne'
import SliderCom from '../helpers/SliderCom'

function TeamSection({ className }) {
  const teamSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const [teamState, setTeamState] = useState([])
  const { isLoading } = useQuery('getTeam', getTeam, {
    refetchOnWindowFocus: false,
    onSuccess: (res) => setTeamState(res.data),
    onError: (err) => console.log(err),
  })

  return (
    <section
      className={`team-section pt-115 rpt-95 pb-60 rpb-40 ${className || ''}`}
    >
      <div className='container'>
        <div className='section-title text-center mb-55'>
          <h2>Our Awesome Team</h2>
        </div>
        <div className='team_slider'>
          <div className='py-65'>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  gap: '2em',
                }}
              >
                {[...Array(4).keys()].map((key) => (
                  <div
                    key={key}
                    style={{
                      width: '25%',
                      px: '.5em',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1em',
                    }}
                  >
                    <Skeleton height='270px' />
                    <Skeleton height='120px' />
                  </div>
                ))}
              </div>
            ) : (
              teamState?.length > 0 && (
                <SliderCom
                  settings={{
                    ...teamSettings,
                    infinite: teamState?.length > 4,
                  }}
                >
                  {teamState.map((person) => (
                    <div key={person.id}>
                      <TeamCardStyleOne person={person} />
                    </div>
                  ))}
                </SliderCom>
              )
            )}
          </div>
        </div>
        <div className='row justify-content-center'></div>
      </div>
    </section>
  )
}

export default TeamSection
