import testimonials from '../../data/testimonials.json'
import SliderCom from '../helpers/SliderCom'

export default function TestimonialSection() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    arrows: false,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  const settingsClient = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }
  return (
    <section
      className='testimonial-section bg-lighter mt-220 rpt-95 pb-120 rpb-100'
      id='testimonial-section'
    >
      <div className='container'>
        <div className='logo-carousel-wrap style-two bg-white pt-65 pb-100 px-25 br-5'>
          <div className='row justify-content-center mb-25'>
            <div className='col-lg-6 col-md-8 col-sm-10'>
              <div className='section-title text-center mb-45'>
                <h2>Our Clients</h2>
              </div>
            </div>
          </div>
          <SliderCom settings={settingsClient}>
            <div className='logo-item'>
              <a href='#'>
                <img src='/client_logos/CoveSmart.png' alt='Client Logo' />
              </a>
            </div>
            <div className='logo-item'>
              <a href='#'>
                <img src='/client_logos/Krawl.png' alt='Client Logo' />
              </a>
            </div>
            <div className='logo-item'>
              <a href='#'>
                <img src='/client_logos/Kuwait Outlet.jfif' alt='Client Logo' />
              </a>
            </div>
            <div className='logo-item'>
              <a href='#'>
                <img src='/client_logos/mindbuilding.jpg' alt='Client Logo' />
              </a>
            </div>
            <div className='logo-item'>
              <a href='#'>
                <img src='/client_logos/Panda.png' alt='Client Logo' />
              </a>
            </div>
            <div className='logo-item'>
              <a href='#'>
                <img src='/client_logos/Spotbox.jpeg' alt='Client Logo' />
              </a>
            </div>
            <div className='logo-item'>
              <a href='#'>
                <img src='/client_logos/Stripway.png' alt='Client Logo' />
              </a>
            </div>
            <div className='logo-item'>
              <a href='#'>
                <img
                  src='/client_logos/Style Protocol.jfif'
                  alt='Client Logo'
                />
              </a>
            </div>
            <div className='logo-item'>
              <a href='#'>
                <img src='/client_logos/styleXchange.jfif' alt='Client Logo' />
              </a>
            </div>
            <div className='logo-item'>
              <a href='#'>
                <img src='/client_logos/YourJustice.PNG' alt='Client Logo' />
              </a>
            </div>
          </SliderCom>
        </div>
        <div className='section-title text-center mb-65'>
          <h2>What people say about us</h2>
        </div>
        <div className='fact-counter-color text-center mb-30'></div>
        <div className='testimonial-wrap'>
          <SliderCom settings={settings}>
            {testimonials?.reviews &&
              testimonials?.reviews?.length > 0 &&
              testimonials?.reviews?.map(
                ({ avatar, name, role, message }, key) => (
                  <div
                    className='testimonial-item wow fadeInLeft delay-0-2s'
                    key={key}
                  >
                    <div className='author-description'>
                      <img
                        src={
                          avatar ||
                          require(`../../assets/images/testimonials/testi-author-1.jpg`)
                            .default
                        }
                        alt='Author'
                      />
                      <div className='designation'>
                        <h5>{name || ''}</h5>
                        <span>{role || ''}</span>
                      </div>
                      <i className='fas fa-quote-right'></i>
                    </div>
                    <p
                      style={{
                        textAlign: 'justify',
                      }}
                    >
                      {message || ''}
                    </p>
                  </div>
                )
              )}
          </SliderCom>
        </div>
      </div>
    </section>
  )
}
