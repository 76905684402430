import { Link, useLocation } from 'react-router-dom'
import { services } from '../../data/services.json'

function Navigation({ className }) {
  const { pathname } = useLocation()
  return (
    <>
      <div className={`restly-header-main-menu ${className || ''}`}>
        <ul>
          <li
            className={`qstl_navigation ${
              pathname === '/' && 'qstl_navigation-active'
            }`}
          >
            <Link to='/'>
              Home
              {/* <i className='fa fa-angle-down' /> */}
            </Link>
            {/* <ul className="sub-menu">
              <li>
                <Link to="/">Home 1</Link>
              </li>
              <li>
                <Link to="/home-two">Home 2</Link>
              </li>
            </ul> */}
          </li>
          <li
            className={`qstl_navigation ${
              pathname === '/about' && 'qstl_navigation-active'
            }`}
          >
            <Link to='/about'>
              About
              {/* <i className='fa fa-angle-down' /> */}
            </Link>
            {/* <ul className='sub-menu'>
              <li>
                <Link to='/about-one'>About One</Link>
              </li>
            </ul> */}
          </li>
          <li
            className={`qstl_navigation ${
              (pathname === '/services' ||
                pathname.includes('service-details')) &&
              'qstl_navigation-active'
            }`}
          >
            <a>
              Services <i className='fa fa-angle-down' />
            </a>
            <ul className='sub-menu services_dropdown_menu'>
              {services.map((service) => (
                <li
                  key={service.id}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Link to={`/${service.slogan}`}>
                    {service.accronym ? service.accronym : service.service_name}
                  </Link>
                </li>
              ))}

              {/* <li>
                <Link to="/service-two">Service Two</Link>
              </li>
              <li>
                <Link to="/service-three">Service Three</Link>
              </li>
              <li>
                <Link to="/service-details">Service Details</Link>
              </li> */}
            </ul>
          </li>
          <li
            className={`qstl_navigation ${
              pathname === '/portfolio' && 'qstl_navigation-active'
            }`}
          >
            <Link to='/portfolio'>
              Portfolio
              {/* <i className='fa fa-angle-down' /> */}
            </Link>
            {/* <ul className='sub-menu'>
              <li>
                <Link to='/portfolio-one'>Portfolio One</Link>
              </li>
            </ul> */}
          </li>
          <li>
            <Link to='/blog'>
              Blog
              {/* <i className='fa fa-angle-down' /> */}
            </Link>
            {/* <ul className='sub-menu'>
              <li>
                <Link to='/blog'>Blog Page</Link>
              </li>
              <li>
                <Link to='/blog/blog-details'>Blog details</Link>
              </li>
            </ul> */}
          </li>
          {/* <li>
            <Link to='/contact'>Contact</Link>
          </li> */}
        </ul>
      </div>
    </>
  )
}

export default Navigation
