import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from 'react-query'
import { getPortfolio } from '../../Services/Portfolio'
import SliderCom from '../helpers/SliderCom'

function GallerySection() {
  const gallerySettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const nestedGallerySettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    swipeToSlide: true,
  }

  const [portfolio, setPortfolio] = useState([])
  const { isLoading } = useQuery('getPortfolio', getPortfolio, {
    refetchOnWindowFocus: false,
    onSuccess: (res) => setPortfolio(res.data),
    onError: (err) => console.log(err),
  })

  return (
    <section className='gallery-section text-white pt-115 rpt-95 pb-100 rpb-80'>
      <div className='container-fluid px-0'>
        <div className='section-title text-center mb-55'>
          {/* <span className='sub-title'>Our Gallery</span> */}
          <h2>Some Of Our Work</h2>
        </div>
        {isLoading ? (
          <div className='row'>
            <div className='col-md-3 p-1'>
              <Skeleton height='270px' width='100%' />
            </div>
            <div className='col-md-3 p-1'>
              <Skeleton height='270px' width='100%' />
            </div>
            <div className='col-md-3 p-1'>
              <Skeleton height='270px' width='100%' />
            </div>
            <div className='col-md-3 p-1'>
              <Skeleton height='270px' width='100%' />
            </div>
          </div>
        ) : (
          portfolio?.length > 0 && (
            <SliderCom settings={gallerySettings}>
              {portfolio
                .sort(() => Math.random() - 0.5)
                .map((project) => (
                  // <Link to='/portfolio'>
                  <div
                    className='gallery-item wow fadeInUp delay-0-2s'
                    key={project?.id}
                  >
                    <img src={project?.mocup} height='100%' alt='Gallery' />
                    {project?.gallery && project?.gallery.length > 0 && (
                      <div className='nested-gallery'>
                        <SliderCom settings={nestedGallerySettings}>
                          {project?.gallery.map((img, index) => (
                            <div className='nested-gallery-image' key={index}>
                              <img src={img} alt='Gallery' />
                            </div>
                          ))}
                        </SliderCom>
                      </div>
                    )}
                    <div className='gallery-content'>
                      <span
                        className='category'
                        style={{ textTransform: 'capitalize' }}
                      >
                        {project?.category?.split('_').join(' ').toLowerCase()}
                      </span>
                      <h5>{project?.title}</h5>
                    </div>
                  </div>
                  // </Link>
                ))}
            </SliderCom>
          )
        )}
      </div>
    </section>
  )
}

export default GallerySection
