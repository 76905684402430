import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Layouts from '../../helpers/Layouts/index'
import MainSection from './MainSection'

export default function Blog() {
  const [params, setParams] = useState(null)
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const singleValue = queryParams.get('category')

    if (!singleValue) {
      setParams(null)
      return
    }

    setParams(singleValue)
  }, [location])

  return (
    <Layouts pageTitle='Blog'>
      <MainSection queryParams={params} />
    </Layouts>
  )
}
