import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getCategories } from '../../../Services'
import { getBlogDetails, getBlogs } from '../../../Services/Blogs'
import Layouts from '../../helpers/Layouts'
import MainSection from './MainSection'

export default function BlogDetails() {
  const { blogId } = useParams()

  const [recentBlogs, setRecentBlogs] = useState(null)
  // const { isLoading: isBlogsLoading } =
  useQuery('blogs', getBlogs, {
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => setRecentBlogs(data.slice(0, 3)),
  })

  const [blogDetails, setBlogDetails] = useState(null)
  const { isLoading: isBlogLoading } = useQuery(
    'blogDetails',
    () => getBlogDetails(blogId),
    {
      onSuccess: ({ data }) => setBlogDetails(data),
      refetchOnWindowFocus: false,
      enabled: !!blogId,
    }
  )

  const [categories, setCategories] = useState([])
  const { isLoading: isCategoriesLoading } = useQuery(
    'categories',
    getCategories,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => setCategories(data),
    }
  )

  return (
    <>
      <Layouts
        pageTitle={blogDetails?.title || 'blog details'}
        bg={blogDetails?.image}
      >
        <MainSection
          blog={{ details: blogDetails, isBlogLoading }}
          categories={{ isCategoriesLoading, categories }}
          recentBlogs={recentBlogs}
        />
      </Layouts>
    </>
  )
}
