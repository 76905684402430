import Background from '../../assets/images/counter/counter-bg.jpg'
import testimonials from '../../data/testimonials.json'
import CounterUp from '../helpers/CounterUp'

function AboutSection({ className, funFact = false, about }) {
  return (
    <section
      className={`about-section bg-black pt-120 ${
        !funFact && 'pb-120'
      } rpt-100 ${className || ''}`}
      id='about-section '
    >
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-lg-5'>
            <div className='about-image-shape rmb-70 wow fadeInLeft delay-0-2s'>
              <img src='/work.svg' alt='About' />
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='about-content text-white pr-70 rpr-0 wow fadeInRight delay-0-2s'>
              <div className='section-title section-title-underlined mb-30'>
                <h2
                  style={{
                    fontFamily: 'Rubik, sans-serif',
                    letterSpacing: '1px',
                    fontSize: '30px',
                    fontWeight: 500,
                    padding: '8px 0px',
                  }}
                >
                  Our Mission
                </h2>
              </div>
              <p style={{ textAlign: 'justify' }}>{about?.shortBio || ''}</p>
              <ul className='list-style-one'>
                {about?.taglines?.length > 0 &&
                  about?.taglines?.splice(0, 5).map((tag, i) => (
                    <li key={i}>
                      <span style={{ width: '90%' }}>{tag}</span>
                    </li>
                  ))}
                {/* <li>which means as the company ramps</li>
                <li>Available in 14.1-in. and 16-in. versions.</li>
                <li>The potential loss of the Touch Bar.</li>
                <li>High-speed USB 4.0.</li> */}
              </ul>
            </div>
          </div>
        </div>
        {funFact && (
          <div
            className='fact-counter-inner br-5 px-25 pt-80 pb-30 text-white text-center'
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className='row'>
              <div className='col-lg-3 col-sm-6'>
                <div className='success-item wow fadeInUp delay-0-2s'>
                  <span
                    className='count-text'
                    data-speed='5000'
                    data-stop='520'
                  >
                    <CounterUp
                      endValue={testimonials?.projectsDone || 350}
                      sectionSelect='about-section '
                    />
                  </span>
                  <p>Projects Done</p>
                </div>
              </div>
              <div className='col-lg-3 col-sm-6'>
                <div className='success-item wow fadeInUp delay-0-4s'>
                  <span className='count-text plus'>
                    <CounterUp
                      endValue={testimonials?.awardsWon || 25}
                      sectionSelect='about-section '
                    />
                  </span>
                  <p>Awards Won</p>
                </div>
              </div>
              <div className='col-lg-3 col-sm-6'>
                <div className='success-item wow fadeInUp delay-0-6s'>
                  <span
                    className='count-text plus'
                    data-speed='5000'
                    data-stop='12'
                  >
                    <CounterUp
                      endValue={testimonials?.yearsExperience || 10}
                      sectionSelect='about-section '
                    />
                  </span>
                  <p>Years Experience</p>
                </div>
              </div>
              <div className='col-lg-3 col-sm-6'>
                <div className='success-item wow fadeInUp delay-0-8s'>
                  <span
                    className='count-text plus'
                    data-speed='5000'
                    data-stop='352'
                  >
                    <CounterUp
                      endValue={testimonials?.happyClients || 350}
                      sectionSelect='about-section '
                    />
                  </span>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default AboutSection
