import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from 'react-query'
import { getPortfolio } from '../../../Services/Portfolio'

export default function MainSection() {
  const [isotope, setIsotope] = useState(null)
  const [filterKey, setFilterKey] = useState('all')

  const handleFilterKeyChange = (key) => setFilterKey(key)
  const replaceCommaToSpace = (value) => value.join(' ').toLowerCase()

  const [portfolio, setPortfolio] = useState([])
  const { isLoading } = useQuery('getPortfolio', getPortfolio, {
    refetchOnWindowFocus: false,
    onSuccess: (res) =>
      setPortfolio(
        res.data.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        )
      ),
    onError: (err) => console.log(err),
  })

  useEffect(() => {
    if (!isLoading && portfolio.length > 0) {
      setTimeout(() => {
        setIsotope(
          // eslint-disable-next-line no-undef
          new Isotope('.portfolio-wrap', {
            // filter-container: className of the parent of the isotope elements
            itemSelector: '.item', // filter-item: className of the isotope elements
            // layoutMode: "fitRows", // for horizontal isotope
          })
        )
      }, 100)
    }
  }, [portfolio, isLoading])

  useEffect(() => {
    if (isotope) {
      // sanity check
      // eslint-disable-next-line no-unused-expressions
      filterKey === 'all'
        ? isotope.arrange({ filter: `.all` })
        : isotope.arrange({ filter: `.${filterKey}` })
    }
  }, [isotope, filterKey, portfolio])
  return (
    <section className='portfolio-section pt-115 rpt-95 pb-90 rpb-70'>
      <div className='container'>
        <ul className='portfolio-filter mb-45'>
          <li
            onClick={() => handleFilterKeyChange('all')}
            className={filterKey === 'all' ? 'current' : ''}
          >
            Show All
          </li>
          <li
            onClick={() => handleFilterKeyChange('web')}
            className={filterKey === 'web' ? 'current' : ''}
          >
            Web Development
          </li>
          <li
            onClick={() => handleFilterKeyChange('mobile')}
            className={filterKey === 'mobile' ? 'current' : ''}
          >
            Mobile App Development
          </li>
        </ul>
        {isLoading ? (
          <div className='row' style={{ marginBottom: '30px' }}>
            {[...Array(6).keys()].map((key) => (
              <div className='col-lg-4 col-sm-6' key={key}>
                <Skeleton height={350} />
              </div>
            ))}
          </div>
        ) : (
          <div className='row portfolio-wrap h-auto-important'>
            {portfolio?.length > 0 &&
              portfolio.map((item) => (
                <div
                  key={item.id}
                  className={`col-lg-4 col-sm-6 item ${
                    item?.filters?.length > 0
                      ? // eslint-disable-next-line no-unused-vars
                        replaceCommaToSpace(item.filters)
                      : ''
                  }`}
                >
                  <div className='gallery-item style-three wow fadeInUp delay-0-2s'>
                    {/* <img
                    src={`/mocups/${item?.title}/${item.mocup}`}
                    // src='https://wallpaperaccess.com/full/11737.jpg'
                    alt='Gallery'
                    loading='lazy'
                  /> */}
                    <LazyLoadImage
                      alt='Gallery'
                      effect='blur'
                      src={item.mocup}
                      width='100%'
                      height='100%'
                    />

                    <div
                      className='gallery-content gallery-content-portfolio'
                      style={{ pointerEvents: 'all' }}
                    >
                      {item.title && (
                        <div className='flex'>
                          <span>
                            <h5>Name:</h5>
                          </span>
                          <h5>{item.title}</h5>
                        </div>
                      )}
                      {item.category && (
                        <div className='flex'>
                          <span>
                            <h5>Category:</h5>
                          </span>
                          <h5 style={{ textTransform: 'capitalize' }}>
                            {item.category.split('_').join(' ').toLowerCase()}
                          </h5>
                        </div>
                      )}
                      {item.stack && (
                        <div className='flex'>
                          <span>
                            <h5>Stack:</h5>
                          </span>
                          <h5>{item.stack}</h5>
                        </div>
                      )}
                      {item.description && (
                        <div className='flex'>
                          <span>
                            <h5>Description:</h5>
                          </span>
                          <h5>
                            {item.description.substring(0, 132)}{' '}
                            {item.description.length > 132 && '...'}
                          </h5>
                        </div>
                      )}
                      {item.category !== 'MOBILE_APP_DEVELOPMENT' && item.link && (
                        <div className='flex mt-2'>
                          {/* <span>
                          <h5>Website:</h5>
                        </span> */}
                          <div
                            style={{
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              bottom: '4%',
                              textAlign: 'center',
                            }}
                          >
                            <a
                              href={item.link || '#'}
                              target='_blank'
                              rel='noreferrer'
                              className='theme-btn'
                              style={{ textDecoration: 'none' }}
                            >
                              Visit Site
                            </a>
                          </div>
                        </div>
                      )}
                      {item.category === 'MOBILE_APP_DEVELOPMENT' && (
                        <div
                          style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: '4%',
                            textAlign: 'center',
                            padding: '0 24px 0 12px',
                          }}
                          className='gallery-items-app-links'
                        >
                          {item.playstore && (
                            <a
                              href={item.playstore || '#'}
                              target='_blank'
                              rel='noreferrer'
                              className='gallery-item-play-button'
                            >
                              <img
                                src='/google-play-badge.png'
                                alt='Google Play Badge'
                              />
                            </a>
                          )}
                          {item.appstore && (
                            <a
                              href={item.appstore || '#'}
                              target='_black'
                              rel='noreferrer'
                              className='gallery-item-app-button'
                            >
                              <img
                                src='/app-store-badge.png'
                                alt='App Store Badge'
                              />
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </section>
  )
}
