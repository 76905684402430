import { useContext, useEffect } from 'react'
import DocumentMeta from 'react-document-meta'
import { LoadingContext } from 'react-router-loading'
import BacktoTopCom from '../helpers/BackToTopCom'
import TestimonialSection from '../HomeThree/TestimonialSection'
import TeamSection from '../HomeTwo/TeamSection'
import FooterHomeOne from '../partials/Footers/FooterHomeOne'
import HeaderHomeThree from '../partials/Headers/HeaderHomeThree'
import AboutSection from './AboutSection'
import FeatureSection from './FeatureSection'
import GallerySection from './GallerySection'
import Hero from './Hero'
import ServiceSection from './ServiceSection'

function HomeOne() {
  const { done } = useContext(LoadingContext)
  useEffect(() => {
    const loading = async () => {
      done()
    }
    loading()
  }, [done])
  const meta = {
    title: 'Quantum Skye Technologies Ltd | Mobile and Web Development Agency',
    description:
      'Welcome to QSTL. Using our exceptional agile experience, we focus on meeting our customers’ expectations of an innovative digital experience. Get in touch!',
    meta: {
      charset: 'utf-8',
      name: {
        keywords:
          'homepage,qstl homepage ,web development,mobile development,seo,',
      },
    },
  }
  const about = {
    taglines: [
      'Building Products of The Future.',
      'Transforming vision to value.',
      'Crafting your ideas and bringing them to life.',
      'Perfect Blend of Superlative IT Services with Cutting-Edge Technology.',
      'We Design, Develop & Execute Digital Experiences.',
      'We turn ideas in to products of future.',
    ],
    shortBio:
      'Quantum Skye Technologies is much more than a team of techies; we are your strategic partner. Using our exceptional agile experience and a highly knowledgeable team, we do one thing only: Focus on meeting our customers’ expectations of an innovative digital experience.',
  }
  return (
    <DocumentMeta {...meta}>
      <div style={{ overflowX: 'hidden' }}>
        <HeaderHomeThree />
        <Hero />
        <ServiceSection />
        <AboutSection about={about} />
        <FeatureSection />
        {/* <WorkProcessSection /> */}
        <GallerySection />
        <TestimonialSection />
        <TeamSection />
        <FooterHomeOne />

        <BacktoTopCom />
      </div>
    </DocumentMeta>
  )
}

export default HomeOne
