import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo2 from '../../../../assets/images/logos/logo-two.png'
import contact from '../../../../data/contact.json'
import StickyMenu from '../../../../lib/StickyMenu'
import Navigation from '../../../helpers/Navigation'
import MobileHeaderCom from '../../Mobile/MobileHeaderCom'

export default function HeaderHomeThree() {
  useEffect(() => {
    StickyMenu('.main-header')
  })
  return (
    <>
      <MobileHeaderCom logo={logo2} />
      <header className='main-header header-three text-white'>
        <div className='header-top-wrap bg-black py-15'>
          <div className='container'>
            <div className='header-top'>
              <div className='top-left'>
                <ul>
                  <li>
                    Call Us:{' '}
                    <a href={`callto:${contact?.phone || ''}`}>
                      {contact?.phone || ''}
                    </a>
                  </li>
                </ul>
              </div>
              <div className='top-mid'>
                <ul>
                  <li>
                    Email us:{' '}
                    <a href={`mailto:${contact?.email || ''}`}>
                      {contact?.email || ''}
                    </a>
                  </li>
                </ul>
              </div>
              <div className='top-right'>
                {/* <div className='office-time'>
                  <i className='far fa-clock'></i>
                  <span>{contact?.timing || ''}</span>
                </div> */}
                <div className='social-style-one'>
                  <a
                    href={contact?.social?.facebook || 'http://facebook.com'}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fab fa-facebook-f'></i>
                  </a>
                  <a
                    href={contact?.social?.twitter || 'http://twitter.com'}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fab fa-twitter'></i>
                  </a>
                  <a
                    href={
                      contact?.social?.instagram || 'https://www.instagram.com/'
                    }
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fab fa-instagram'></i>
                  </a>
                  <a
                    href={
                      contact?.social?.linkedIn || 'https://www.linkedin.com/'
                    }
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fab fa-linkedin'></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='header-upper'>
          <div className='container clearfix'>
            <div className='header-inner d-flex align-items-center'>
              <div className='logo-outer'>
                <div className='logo'>
                  <a
                    href='/'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        height: 80,
                        width: 160,
                        backgroundBlendMode: 'color-burn',
                        objectFit: 'contain',
                      }}
                      src={logo2}
                      alt='Logo'
                      title='Logo'
                    />
                    <li
                      style={{
                        whiteSpace: 'nowrap',
                        // marginLeft: -14,
                        fontSize: 24,
                        wordSpacing: 2,
                        letterSpacing: 2,
                      }}
                    >
                      {/* <span
                        style={{
                          // color: "#c11112",
                          fontSize: "48px",
                          fontWeight: "bold",
                          marginRight: 2,
                          fontFamily: "",
                        }}
                      >
                        Q
                      </span> */}
                      Quantum Skye Technologies
                    </li>
                  </a>
                </div>
              </div>

              <div className='nav-outer clearfix d-flex align-items-center'>
                <div className='main-menu navbar-expand-lg'>
                  <Navigation />
                </div>
                <div className='menu-btn'>
                  <Link to='/contact' className='theme-btn'>
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
