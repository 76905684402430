export default function AboutSection({ className }) {
  return (
    <section className={`about-three py-120 rpy-100 ${className || ''}`}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='about-three-image rmb-60 mr-10 rmr-0 wow fadeInLeft delay-0-2s'>
              <img
                src={
                  require(`../../assets/images/about/software_engineer.svg`)
                    .default
                }
                alt='About'
              />
              <img
                src={require(`../../assets/images/about/co-work.svg`).default}
                alt='About'
              />
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='about-content pl-70 rpl-0 wow fadeInRight delay-0-2s'>
              {/* <div className='section-title mb-35'>
                <span className='sub-title'>It Support For Business</span>
                <h2>Quantum Skye Technologies.</h2>
              </div> */}
              <p>
                We’re much more than a team of techies; we are your strategic
                partner. Using our exceptional agile experience and a highly
                knowledgeable team, we do one thing only: Focus on meeting our
                customers’ expectations of an innovative digital experience.
              </p>
              <p>
                QSTL has delivered excellent products from the inception of the
                idea, to requirements, design, coding, testing, and
                disseminating. We develop innovative creative products and
                services that provide total communication and information
                solutions. Among a plethora of services, web design and
                development, tailor made applications, ERPs, CRMs, e-commerce
                solutions, business-to-business applications, business-to-client
                applications, Digital Marketing are few that we offer. Satisfied
                clients around the globe bear testimony to the quality of our
                work.
              </p>
              {/* <ul className='list-style-three mt-15'>
                {about?.taglines?.length > 0 &&
                  about?.taglines.map((tag) => <li>{tag}</li>)}
                {/* <li>which means as the company ramps</li>
                <li>Available in 14.1-in. and 16-in. versions.</li>
                <li>The potential loss of the Touch Bar.</li>
                <li>High-speed USB 4.0.</li> 
              </ul>
              {/* <a href='/about' className='theme-btn style-three mt-25'>
                View details
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
