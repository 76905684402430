import { Link } from "react-router-dom";
import { services } from "../../data/services.json";

function ServiceSection() {
  return (
    <section className="services-section pt-120 rpt-100 pb-90 rpb-70 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="section-title text-center mb-35">
              {/* <span className='sub-title'>CORE EXPERTISE</span> */}
              <h2>CORE EXPERTISE</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {services.slice(0, 4).map((service, index) => (
            <div
              className="col-xl-3 col-sm-6"
              key={`${service.id}_${service.service_name}_${index}`}
            >
              <div className="service-box wow fadeInUp delay-0-2s text-center">
                <div className="service-normal">
                  <div className="icon">
                    <i className={`flaticon ${service.iconClass}`}></i>
                  </div>
                  <h6>{service.service_name}</h6>
                  <p
                    style={{
                      height: 120,
                      margin: 0,
                    }}
                  >
                    {service.card_title}{" "}
                  </p>
                  {/* <a className='btn-circle' href='/service-details'>
                    <i className='fas fa-long-arrow-alt-right'></i>
                  </a> */}
                </div>
                <div className="service-hover bg-black text-white justify-content-center text-center">
                  {/* <h3>Preparing For Your Business Success With IT Solution</h3> */}
                  <h5>{service.card_overlay_title}</h5>
                  <Link to={`/${service.slogan}`} className="theme-btn">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          ))}

          {/* <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web-development-4"></i>
                </div>
                <h6>IT Management</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-black text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisc</p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-6s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web"></i>
                </div>
                <h6>Cloud Services</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-black text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisc</p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-data"></i>
                </div>
                <h6>Machine Learning</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-black text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisc</p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;
